export class NRQuestionModel {
    autoID: number;
    enc_AutoID: string;
    clientID: number;
    enc_ClientID: string;
    questionSeven: NRQuestionSeven[];
    q1: string;
    q2: string;
    q3: string;
    q4a: string;
    q4b: string;
    q4c: string;
    q5: string;
    q6: string;
    q6a: string;
    q7: string;
    q8: string;
    q8a: string;
    q9: string;
    constructor() {
        this.autoID = 0;
        this.enc_AutoID = '';
        this.clientID = 0;
        this.enc_ClientID = '';
        this.questionSeven = [];
        this.q1 = '';
        this.q2 = '';
        this.q3 = '';
        this.q4a = '';
        this.q4b = '';
        this.q4c = '';
        this.q5 = '';
        this.q6 = '';
        this.q6a = '';
        this.q7 = '';
        this.q8 = '';
        this.q8a = '';
        this.q9 = '';
    }
}

export class NRQuestionSeven {
    enteredDate: any;
    departedDate: any;
    isEditingOn: boolean;
    constructor() {
        this.enteredDate = '';
        this.departedDate = '';
        this.isEditingOn = false;
    }
}
