export class TaxSituationModel {

    public autoId: number;
    public firstName: string;
    public lastName: string;
    public pphone: string;
    public city: string;
    public enc_ClientID: string;
    public isGreenCard: boolean;
    public isusCitizen: boolean;
    public visaType: string;
    public maritalStatus: string;
    public daysInUS: number;
    public daysInUS_1: number;
    public daysInUS_2: number;
    public incomeType: string;
    public spouseIncomeType: string;
    public otherIncome: string;
    public spouseOtherIncome: string;
    public isResidentOfUS: boolean;

    constructor() {
        this.autoId = 0;
        this.enc_ClientID = '';
        this.firstName = '';
        this.lastName = '';
        this.pphone = '';
        this.city = '';
        this.isusCitizen = true;
        this.isGreenCard = false;
        this.visaType = 'Select';
        this.maritalStatus = 'Single';
        this.daysInUS = 0;
        this.daysInUS_1 = 0;
        this.daysInUS_2 = 0;
        this.incomeType = '';
        this.spouseIncomeType = '';
        this.otherIncome = '';
        this.spouseOtherIncome = '';
        this.isResidentOfUS = true;
    }
}

export class Income {
    W2_Income: boolean;
    Indian_Salary: boolean;
    Interest: boolean;
    OtherIncome: boolean;
    constructor() {
        this.W2_Income = false;
        this.Indian_Salary = false;
        this.Interest = false;
        this.OtherIncome = false;
    }
}

export class OtherIncome {
    Indian_Interest: boolean;
    Indian_Dividend: boolean;
    Mutual_Funds: boolean;
    Rental_Income: boolean;
    Capital_Gains: boolean;
    Business_Income: boolean;

    constructor() {
        this.Indian_Interest = false;
        this.Indian_Dividend = false;
        this.Mutual_Funds = false;
        this.Rental_Income = false;
        this.Capital_Gains = false;
        this.Business_Income = false;
    }
}