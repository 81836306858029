import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseModel } from 'src/app/Core/Models/ResponseModel';
import { BaseurlService } from 'src/app/CommonService/baseurl.service';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { AdvisorProfileModel } from '../../../Core/Models/AdvisorProfileModel';

@Injectable({
    providedIn: 'root'
})

export class DocumentService {

    constructor(private httpClient: HttpClient, private baseUrlService: BaseurlService) {

    }

    getMimeType(){
        return this.httpClient.get<ResponseModel>(
            this.baseUrlService.baseUrl+'Common/GetMimeType'
        );
    }


    UploadDocuments(formdata) {
        return this.httpClient
            .post<ResponseModel>(
                this.baseUrlService.baseUrl + 'Documents/UploadFile',
                formdata
            );
    }


    GetDocumentList(enc_clientID,IncomePageIndex) {
        return this.httpClient
            .get<ResponseModel>(
                this.baseUrlService.baseUrl + 'Documents/GetFileList?Enc_ClientID=' + enc_clientID + '&IncomePageIndex='+IncomePageIndex
            );
    }

    DownloadFile(enc_clientID,enc_FileID){
        //return this.httpClient
        // .get<ResponseModel>(
        //     this.baseUrlService.baseUrl + 'Documents/DownloadFile?Enc_ClientID=' + enc_clientID + '&Enc_FileID='+enc_FileID
        // ); 

         window.open(this.baseUrlService.baseUrl + 'Documents/DownloadFile?Enc_ClientID=' + enc_clientID + '&Enc_FileID='+enc_FileID)
    }

    DeleteFile(Enc_ClientID,Enc_FileID){
        return this.httpClient
        .get<ResponseModel>(
            this.baseUrlService.baseUrl + 'Documents/DeleteFile?Enc_FileID='+Enc_FileID + '&Enc_ClientID=' + Enc_ClientID 
        );
    }

    UpdateFileDescription(Description,Enc_FileID){
        return this.httpClient
        .get<ResponseModel>(
            this.baseUrlService.baseUrl + 'Documents/UpdateFileDescription?description='+Description + '&encFileID=' + Enc_FileID 
        );
    }

    SendEmailToAdvisorClientUploadedFile(Advisor:AdvisorProfileModel){
        return this.httpClient
        .post<ResponseModel>(
            this.baseUrlService.baseUrl + 'Email/SendEmailToAdvisorClientUploadedFile',
            Advisor
        )
    }

}

