export class IncomeInfoModel {
    enc_ClientID: string;
    clientID: number;
    q1: string;
    q2: string;
    q2a: string;
    q3: string;
    q4: string;
    q4a: string;
    q4b: string;
    q5: string;
    q6: string;
    q6a: string;
    q7: string;
    q8: string;
    q9: string;
    q10: string;
    q11: string;
    q12: string;
    q13: string;
    q14: string;
    q14a: string;
    q14b: string;
    q14c: string;
    q14d: string;
    q14e: string;
    q14f: string;
    q15: string;
    q16: string;
    q17: string;
    q18: string;
    q19: string;
    q20: string;
    q21: string;
    q22: string;
    q23: string;
    q21a: string;
    q23a: string;
    q24: string;
    q24a: string;

    constructor() {
        this.enc_ClientID = '';
        this.clientID = 0;
        this.q1 = '';
        this.q2 = '';
        this.q2a = '';
        this.q3 = '';
        this.q4 = '';
        this.q4a = '';
        this.q4b = '';
        this.q5 = '';
        this.q6 = '';
        this.q6a = '';
        this.q7 = '';
        this.q8 = '';
        this.q9 = '';
        this.q10 = '';
        this.q11 = '';
        this.q12 = '';
        this.q13 = '';
        this.q14 = '';
        this.q14a = '';
        this.q14b = '';
        this.q14c = '';
        this.q14d = '';
        this.q14e = '';
        this.q14f = '';
        this.q15 = '';
        this.q16 = '';
        this.q17 = '';
        this.q18 = '';
        this.q19 = '';
        this.q20 = '';
        this.q21 = '';
        this.q22 = '';
        this.q23 = '';
        this.q21a = '';
        this.q23a = '';
        this.q24 = '';
        this.q24a = '';
    }
}